export const objectsMixin = {
  methods: {
    objectHasNulls(object) {
      return Object.values(object).some(value => (value === null || value === ''))
    },
    clearObject(object) {
      Object.keys(object).forEach(key => object[key] = null)
    },
    clearObjectWithEmptyString(object) {
      Object.keys(object).forEach(key => object[key] = '')
    },
    clearAllObjects(objects) {
      objects.forEach(object => {
        this.clearObject(object)
      })
    }
  }
}