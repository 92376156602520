export const ordersMixin = {
  data() {
    return {
      orderSelected: {},
      cancellationData: {},
      direccion_envio: {
        calle: '',
        numero_exterior: '',
        numero_interior: '',
        colonia: '',
        codigo_postal: '',
        ciudad: '',
        estado: '',
        latitud: null,
        longitud: null
      },
      cancellationReasons: [],
      cancellations: [],
      dialogAlert: false,
      dialogCancel: false,
      dialogDetail: false,
    }
  },
  computed: {
    findCancellation() {
      return this.cancellations.find(cancellation => cancellation.orden.id === this.orderSelected.id)
    }
  },
  methods: {
    updateOrder(order) {
      this.orderSelected = {...order}
    },
    updateCancellationData(cancellationData) {
      this.cancellationData = {...cancellationData}
      this.cancellationData.id_usuario = this.userData.id
      // console.log("CancellationData: ", this.cancellationData);
    },
    updateConfirmation(confirmation) {
      this.dialogAlert = false
      if (confirmation && this.orderSelected.estatus_orden.dato !== 'Cancelada') {
        this.openCancel(this.orderSelected)
      }
    },
    
    openAlert(order) {
      this.updateOrder(order)
      this.dialogAlert = true
    },
    closeAlert() {
      this.dialogAlert = false
    },
    openCancel(order) {
      this.updateOrder(order)
      this.dialogCancel = true
    },
    openDetail(order) {
      this.updateOrder(order)
      this.dialogDetail = true
    },
    clearOrderSelected() {
      this.orderSelected = {
        estatus_orden: '',
        cliente: '',
        distribuidor: {
          datos_contacto: ''
        }
      }
    },
    clearCancellationData() {
      this.cancellationData = {
        id_orden: null,
        id_usuario: null,
        id_motivo_cancelacion: null,
        id_usuario_aprobador: 0,
        comentarios: null
      }
    },
    clear() {
      // console.log('Se deben limpiar los objetos order selected y cancellationData');
      this.clearOrderSelected()
      this.clearCancellationData()
    },
    addDetailsToOrders(orders, ordersDetails) {
      if (ordersDetails) {
        // console.log('Si tiene detalles');
        orders.forEach(order => {
          const details = ordersDetails.filter(detail => order.id === detail.orden.id)
          if (details.length) {
            order.detalles = details

            const firstDetail = details[0]
            const dir = order.cliente.direcciones_entrega.find(address => address.id === firstDetail.orden.direccion_envio)
            if (dir) {
              order.direccion_envio = dir
            } else {
              const predeterminado = order.cliente.direcciones_entrega.find(address => address.predeterminado === true)
              if (predeterminado) {
                order.direccion_envio = predeterminado
              } else {
                if (order.cliente.direcciones_entrega.length) {
                  order.direccion_envio = order.cliente.direcciones_entrega[0]
                } else {
                  order.direccion_envio = this.direccion_envio
                }
              }
            }
          } else {
            order.detalles = []
          }
        });
      }
      return orders
    },
    async getOrderCancellation(order, axiosInstance) {
      try {
        const response = await axiosInstance.get(`/checkout/api/cancelacion-orden/?id_orden=${order.id}`)
        const responseData = await response.data
      
        // console.log('responseData cancellation: ', responseData);
        return responseData[0]
      } catch (error) {
        console.error('Error Obteniendo cancelacion', error)
      }
    },
  }
}