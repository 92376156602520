<template>
  <div>
    <v-container>
      <v-row justify="space-around">
        <v-col cols="auto">
          <v-dialog
            v-model="dialogo"
            transition="dialog-top-transition"
            width="500"
            persistent
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">Subir Factura</span>
              </v-card-title>
              <div style="align-items: center;display: flex;">
                <v-file-input
                  v-model="nombreDocumentoFile"
                  show-size
                  counter
                  multiple
                  label="Sube tu archivo"
                  style="width: 100px;"
                ></v-file-input>

                <v-btn
                  color="primary"
                  width="100"
                  style="text-align:center; top:12px; left:2px; margin:16px; 10px"
                  @click="subirFactura"
                >
                  Subir
                </v-btn>
              </div>
              <br /><br />
              <v-divider></v-divider>
              <v-card-actions class="justify-firts">
                <v-btn text @click="dialogo = false">
                  Cancelar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <!----- ----------------------------------------------------------------------->
    <div elevation="10" class="py-4 px-4" style="background-color: white;">
      <v-dialog
        v-model="dialogDetail"
        width="1000"
        @click:outside="clearOrderSelected"
      >
        <div v-if="dialogDetail">
          <order-detail
            :order="orderSelected"
            :isCustomer="false"
            :cancellation="findCancellation"
          />
        </div>
      </v-dialog>
      <v-dialog v-model="dialogCancel" width="500" @click:outside="clear">
        <div v-if="dialogCancel">
          <cancelacion-simple
            :orderId="orderSelected.id"
            :orderNumber="orderSelected.numero_orden"
            :cancellationReasons="cancellationReasons"
            @cancellationData="updateCancellationData"
          />
        </div>
      </v-dialog>
      <v-dialog v-model="dialogAlert" width="500" @click:outside="clear">
        <confirm-alert :question="msgAlert" @answer="updateConfirmation" />
      </v-dialog>

      <v-row v-if="!loading" class="my-4 mx-4" align="center">
        <v-col class="pa-0" cols="12" align="center">
          <h3 class="mb-4">Mostrar órdenes por estatus:</h3>
        </v-col>
        <v-col
          class="pa-0"
          cols="12"
          md="4"
          v-for="status in ordenesPorEstatus"
          :key="status.id"
        >
          <v-checkbox
            class="ma-0"
            v-model="status.show"
            :label="status.title"
            @change="checked(status.id)"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row
        v-if="!loading && lastChange"
        class="my-4 mx-4 "
        justify="center"
        align="center"
      >
        <p class="my-0 pa-2">Último cambio de estatus:</p>
        <div class="text-center my-2 d-flex">
          <div class="pa-2" style="background-color: #FF3700; color: white;">
            {{ this.lastChange.id }}
          </div>
          <div class="background-element-secondary pa-2" style="color:white;">
            {{ this.lastChange.numero_orden }}
          </div>

          <div
            class="pa-2 mx-2"
            style="background-color: #FF3700; color: white;"
          >
            {{ this.lastChange.estatus_orden.dato }}
            <v-icon class="mx-2 hidden-sm-and-down" color="secondary">
              mdi-arrow-right-thick
            </v-icon>
            <v-icon
              class="mx-2 show-sm-and-down hidden-sm-and-up"
              color="secondary"
            >
              mdi-arrow-down-thick
            </v-icon>
            {{ this.lastChange.estatus_orden.siguiente_estatus.dato }}
          </div>
        </div>
      </v-row>

      <v-row v-if="!loading" class="my-4 mx-4" justify="center">
        <v-col
          class="orden-column my-4 mx-4"
          v-for="statusOrden in ordersToShow"
          :key="statusOrden.id"
          cols="12"
          md="5"
        >
          <h2
            class="py-2 px-2 mb-4 background-element-primary text-center"
            style="border-radius: 5px; color: white; display:fixed;"
          >
            Ordenes con Estatus: {{ statusOrden.title }} ({{
              ordenesPorEstatus[statusOrden.id].ordenes.length
            }})
          </h2>

          <v-row
            id="status-wrapper"
            justify="center"
            style="max-height: 600px; overflow-y: scroll;"
          >
            <v-card
              v-for="orden in statusOrden.ordenes"
              class="mx-2 my-2"
              :key="statusOrden.key + orden.id"
              max-width="250px"
              elevation="5"
            >
              <v-card-title
                class="background-element-secondary px-2 py-2 text-center"
              >
                <span class="d-flex" style="color: white; width: 100%;">
                  {{ orden.numero_orden }}
                </span>
              </v-card-title>

              <v-card-text class="my-2 py-2">
                <div class="text-center my-2 d-flex" style="width: 100%;">
                  <div class="background-element-primary py-2 px-2 orden-label">
                    Cliente:
                  </div>
                  <div class="text-secondary py-2 px-2 orden-dato">
                    {{ orden.cliente.usuario.username }}
                  </div>
                </div>
                <div class="text-center my-2 d-flex" style="width: 100%;">
                  <div class="background-element-primary py-2 px-2 orden-label">
                    Monto:
                  </div>
                  <div class="text-secondary py-2 px-2 orden-dato">
                    {{ orden.total_orden | twoDecimals | commaThousand }}
                  </div>
                </div>
                <div class="text-center my-2 d-flex" style="width: 100%;">
                  <v-btn
                    tile
                    color="#f4f2f2"
                    style="width: 100%; color: rgba(0,0,0,.6);margin: 0; padding: 0"
                    @click="openDetail(orden)"
                  >
                    Ver detalle
                  </v-btn>
                </div>
                <div class="text-center my-2 d-flex" style="width: 100%;">
                  <v-btn
                    tile
                    color="#f4f2f2"
                    style="width: 100%; color: rgba(0,0,0,.6); margin: 0; padding: 0;"
                    @click="openAlert(orden)"
                  >
                    Cancelar orden
                  </v-btn>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <!-- Subir Factura --------->
              <v-btn
                text
                color="primary"
                :disabled="statusOrden.title == 'Creada'"
                @click="openDetailFactura(orden)"
                style="top:5px;left:6px;"
              >
                Subir Factura
              </v-btn>
              <!-- ---------------------------->
              <v-card-actions>
                <v-btn text color="primary" @click="cambiarEstatus(orden)">
                  Cambiar Estatus
                </v-btn>
                <div
                  class="ml-auto pa-1"
                  style="background-color: #FF3700; color: white;"
                >
                  {{ orden.id }}
                </div>
              </v-card-actions>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
      <component-loader v-else-if="loading"></component-loader>
    </div>
  </div>
</template>
<script src="https://unpkg.com/vue-swal"></script>
<script>
import { tFilters } from "@/mixins/filters.js";
import { ordersMixin } from "@/mixins/orders.js";
import { objectsMixin } from "@/mixins/objectsValidations.js";
import { ApiAuth } from "@/api_utils/axios-base";
import componentLoader from "@/components/ComponentLoader";
import { mapActions, mapState } from "vuex";

export default {
  metaInfo: {
    title: 'Estatus de ordenes - Dashboard'
  },
  mixins: [tFilters, ordersMixin, objectsMixin],
  data() {
    return {
      dialogo: false,
      localFile: null,
      ordenId: null,
      file: null,
      nombreDocumentoFile: null,
      lastChange: null,
      orders: [],
      loading: false,
      status: {
        showCreada: false,
        showPagada: false,
      },
      ordenesPorEstatus: [
        {
          id: 0,
          key: "c",
          title: "Creada",
          ordenes: [],
          show: false,
        },
        {
          id: 1,
          key: "p",
          title: "Pagada",
          ordenes: [],
          show: false,
        },
        {
          id: 2,
          key: "asign",
          title: "Asignada a distribuidor",
          ordenes: [],
          show: false,
        },
        {
          id: 3,
          key: "acept",
          title: "Aceptada por distribuidor",
          ordenes: [],
          show: false,
        },
        {
          id: 4,
          key: "proce",
          title: "En proceso de surtido",
          ordenes: [],
          show: false,
        },
        {
          id: 5,
          key: "env",
          title: "Surtida lista para envío",
          ordenes: [],
          show: false,
        },
        {
          id: 6,
          key: "recolec",
          title: "Surtida en espera de recolección",
          ordenes: [],
          show: false,
        },
        {
          id: 7,
          key: "transi",
          title: "En Tránsito",
          ordenes: [],
          show: false,
        },
        {
          id: 8,
          key: "procentre",
          title: "En Proceso de Entrega",
          ordenes: [],
          show: false,
        },
        {
          id: 9,
          key: "entreg",
          title: "Entregada",
          ordenes: [],
          show: false,
        },
        {
          id: 10,
          key: "cancel",
          title: "Cancelada",
          ordenes: [],
          show: false,
        },
        {
          id: 11,
          key: "espconfirm",
          title: "En Espera de confirmación manual de pago",
          ordenes: [],
          show: false,
        },
        {
          id: 12,
          key: "rechaz",
          title: "Pago rechazado",
          ordenes: [],
          show: false,
        },
      ],
    };
  },
  components: {
    componentLoader,
    OrderDetail: () => import("@/components/utils/OrderDetail"),
    CancelacionSimple: () =>
      import("@/components/utils/SimpleForms/CancelacionSimple"),
    ConfirmAlert: () => import("@/components/utils/alerts/ConfirmAlert"),
  },
  watch: {
    cancellationData(val) {
      if (!this.objectHasNulls(val)) {
        this.cancelOrder();
      }
    },
  },
  computed: {
    ...mapState("usuario", ["userData"]),
    ordersToShow() {
      return this.ordenesPorEstatus.filter((orden) => orden.show);
    },
    msgAlert() {
      return this.orderSelected.estatus_orden &&
        this.orderSelected.estatus_orden.dato === "Cancelada"
        ? "La orden ya ha sido cancelada"
        : "Estas seguro de cancelar la orden?";
    },
  },
  methods: {
    ...mapActions("products", ["getProductOptions"]),
    checked(id) {
      //console.log("checked", this.ordenesPorEstatus[id])
    },

    getOrdenesPorEstatus(orders) {
      let ordenes = [];
      this.cleanOrders();

      ordenes = orders;
      /*
      let ordenesxDistribuidor = ordenes.filter(orden => orden."distribuidor" === "distriLogegeado")
      después de esto ahora si filtrar por estatus:
    */
      ordenes.forEach((orden) => {
        let ordenEstatus = orden.estatus_orden.dato;
        this.ordenesPorEstatus.every((estatusData) => {
          if (estatusData.title === ordenEstatus) {
            estatusData.ordenes.push(orden);
            return false;
          }
          return true;
        });
      });
      this.loading = false;
    },
    // Método factura //
    async openDetailFactura(orden) {
      this.ordenId = orden.id;
      this.dialogo = true;
    },
    //Fin de método factura //
    //Subir factura//
    alerta() {},
    async subirFactura() {
      try {
        let errors = null;
        for (const file of this.nombreDocumentoFile) {
          const fileName = file.name;
          // console.log("Nombre del archivo", file.name);
          const formData = new FormData();
          formData.append("documento", file);
          formData.append("nombre_documento", fileName);
          const responseUpload = await ApiAuth.post(
            "/checkout/documento-orden ",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          const responseUploadData = await responseUpload.data;
          // console.log("responseUploadData: ", responseUploadData);
          if (responseUploadData.id) {
            const responseAgregar = await ApiAuth.post(
              "/checkout/agregar-documento-factura",
              {
                id_orden: this.ordenId,
                id_documento: responseUploadData.id,
              }
            );
            const responseAgregarData = await responseAgregar.data;
            if (responseAgregarData.mensaje) {
              setTimeout(
                this.$store.commit("setAlert", {
                  show: true,
                  msg: "Se subio su factura con exito",
                  type: "success",
                }),
                2000
              );
            } else {
              errors++;
            }
          } else {
            errors++;
          }
          if (errors != null) {
            setTimeout(
              this.$store.commit("setAlert", {
                show: true,
                msg: "Ocurrio un problema al subir sus archivos",
                type: "error",
              }),
              2000
            );
          } else {
            this.dialogo = false;
          }
        }
      } catch (error) {
        if (!error) {
          this.dialogo = false;
        } else {
          setTimeout(
            this.$store.commit("setAlert", {
              show: true,
              msg: "Ocurrio un error en el servidor",
              type: "error",
            }),
            6000
          );
          this.dialogo = true;
        }
        console.log(error);
      }
    },
    async cambiarEstatus(orden) {
      try {
        this.loading = true;
        let ordenId = orden.id;
        let response = await ApiAuth.put(
          "/checkout/cambiar-estatus-orden",
          { id_orden: ordenId }
        );

        this.allOrders();
        this.lastChange = orden;
      } catch (error) {
        console.error("error updating order status", error);
      }
    },

    async allOrders() {
      try {
        this.loading = true;
        const distributorId = this.userData.distribuidorId;
        let response = await ApiAuth.get(
          `/reportes/api/ordenes-por-distribuidor/?id_distribuidor=${distributorId}`
        );
        let ordenes = await response.data;

        const detailsResponse = await ApiAuth.get(
          `/checkout/api/detalle-ordenes-distribuidor/?id_distribuidor=${distributorId}`
        );
        const detailsData = await detailsResponse.data;

        ordenes = this.addDetailsToOrders(ordenes, detailsData);

        for (const order of ordenes) {
          if (order.estatus_orden.dato === "Cancelada") {
            const cancellation = await this.getOrderCancellation(
              order,
              ApiAuth
            );
            if (cancellation) {
              this.cancellations.push(cancellation);
            }
          }
        }
        this.getOrdenesPorEstatus(Object.freeze(ordenes));
      } catch (error) {
        console.error("error getting orders", error);
      }
    },
    async getCancellationReasons() {
      try {
        const response = await ApiAuth.get(
          "/checkout/api/motivos-cancelacion/"
        );
        const responseData = await response.data;

        if (responseData.length) {
          this.cancellationReasons = responseData;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async cancelOrder() {
      try {
        const response = await ApiAuth.post(
          "/checkout/cancelar-orden-distribuidor",
          this.cancellationData
        );
        const responseData = await response.data;
        if (responseData.error) {
          // Mandar alerta
          setTimeout(
            this.$store.commit("setAlert", {
              show: true,
              msg:
                "Ocurrio un error y no se pudo mandar la solicitud de cancelacion",
              type: "error",
            }),
            1000
          );
          this.clear();
        } else {
          setTimeout(
            this.$store.commit("setAlert", {
              show: true,
              msg:
                "Se envio la solitud de cancelacion. Mantente pendiente por la respuesta del distribuidor",
              type: "success",
            }),
            1000
          );
        }
        this.dialogCancel = false;
        this.allOrders();
      } catch (error) {
        console.error(error);
      }
    },
    cleanOrders() {
      for (let i = 0; i < this.ordenesPorEstatus.length; i++) {
        this.ordenesPorEstatus[i].ordenes = [];
      }
    },
  },
  created() {
    this.allOrders();
    this.getCancellationReasons(), this.getProductOptions();
  },
};
</script>

<style scoped>
.orden-column {
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.orden-dato {
  background: #f4f2f2;
  font-weight: 500;
  width: 50%;
}
.orden-label {
  width: 50%;
  color: white;
  font-weight: bold;
}
#status-wrapper::-webkit-scrollbar {
  width: 10px;
}
#status-wrapper::-webkit-scrollbar-track {
  background: white;
}
#status-wrapper::-webkit-scrollbar-thumb {
  background-color: #ff3700;
  border-radius: 20px;
  border: 1px solid white;
}
</style>
